<template>
    <div id="succeed">
        <header>
            <!-- <h1>{{`${info.xname ?info.xname : ''}${info.name ? info.name : ''}`}}婚姻测试结果</h1> -->
        </header>
        <section>
            <div class="card_box">
                <i><img src="//cdn.zhiyileiju.cn/paysuccess_icon.png" alt=""></i>
                <h1>支付成功</h1>
                <h2 style="margin-top: 0.5rem;">请点击下方按钮去查看婚姻结果</h2>
                <div class="buttom_box animation_scaling" @click="publicMark"> <i><img src="//cdn.zhiyileiju.cn/WechatIMG426.jpeg" alt=""></i> <span>点击查看婚姻结果</span></div>
                <!-- <p style="color:red;">点击上方按钮进入公众号</p> -->
                <p style="padding:.4rem 0 .6rem 0;">如快速进入失败，仍可按下方教程查看结果</p>
            </div>
            <img src="https://cdn.zhiyileiju.cn/WechatIMG451.jpeg" alt="">
            <h3 style="margin-top: 0.1rem;">婚姻信息</h3>
            <div class="card_box info">
                <h4><span>姓名：{{`${info.xname ? info.xname : ''}${info.name ? info.name :''}`}}</span>  性别：{{info.sex ? info.sex :''}} </h4>
                <h4><span>出生日期：{{`${lunar_calendar_value.cYear ? lunar_calendar_value.cYear :''}年${lunar_calendar_value.cMonth ? lunar_calendar_value.cMonth  :'' }月${lunar_calendar_value.cDay ? lunar_calendar_value.cDay : ''}日${lunar_calendar_value.tiem_ ? lunar_calendar_value.tiem_ : ''}时`}}</span></h4>
                <h4 v-if="info.bind_iphone!==null"><span>手机号码：{{info.bind_iphone ? info.bind_iphone : ''}}</span></h4>
            </div>
            <div class="card_box" style="margin-top:.16rem">
                <h3 style="background:#fff;">快速查看结果图文教程</h3>
                <div class="text_box">
                    <p>第一步<br> 复制微信公众号ID: <span>gh_351d9c738f0c</span> </p>
                    <!-- <button  class="tag-read" data-clipboard-text="gh_78f5b32e4377" @click="copy">复制</button> -->
                    <button  class="tag-read" data-clipboard-text="gh_351d9c738f0c" @click="copy">复制</button>
                </div>
                <img src="https://cdn.zhiyileiju.cn/WechatIMG452.jpeg" alt="">
            </div>
            <div class="buttom_box animation_scaling" @click="openWeixin"> <i style="top:-21%"><img src="//cdn.zhiyileiju.cn/WechatIMG427.pic" alt=""></i> <span>点我打开微信</span></div>
        </section>
        <footer>
            <div class="qr_code_box">
               <img src="https://cdn.zhiyileiju.cn/WechatIMG1439.jpeg" alt="">
            </div>
            <h3>温馨提示</h3>
            <div class="qr_code_box">
               <img src="https://cdn.zhiyileiju.cn/WechatIMG1460.jpeg" alt="">
            </div>
            <div class="qr_code_box animation_scaling" style=" width: 6.4rem; margin: 0 auto;" @click="buttomResult">
               <img src="https://cdn.zhiyileiju.cn/WechatIMG548.png" alt="">
            </div>
        </footer>
        <van-dialog
        v-if="false"
        v-model="showPayDialog"
        :showCancelButton="`false`"
        :showConfirmButton="false"
        style="width:7rem"
        >
        <div class=" dialogbox" v-if="false">
            <div class="tip">绑定手机号查看婚姻结果</div>
            <div class="center formb">
                <span class="span_text"  v-if="!input_fal" @click="displayInputField">点击红框输入常用手机号</span>
            <van-field
                class="input_css"
                v-if="input_fal"
                size="large"
                input-align="center"
                v-model="tel"
                autofocus
                placeholder="请输入手机号，查看婚姻结果"
                type="tel"
            />
            </div>
            <!-- <div class="center agreetip">
            <em class="agree"></em>
            个人隐私保护协议
            </div> -->
            <div class="center" style="margin-top:.6rem">
                <van-button v-if="!input_fal" class="button_text_ button_text " color="#d8d8d8" disabled="true" style="width:6rem;height:1rem;margin: 0 auto;" block round @click="subtel"
                    >点击提交</van-button
                >
                <van-button v-if="input_fal" class="animation_scaling button_text" color="#d0021b" icon="https://cdn.zhiyileiju.cn/WechatIMG226.png" style="width:6rem;height:1rem;margin: 0 auto;" block round @click="subtel"
                    >点击提交</van-button
                >
            </div>
        </div>
        </van-dialog>
        <!--  投诉 -->
        <span  class="fixation_complaint" v-if="complaint_buttom_fal" @click="complaintButtom">
            <img src="https://cdn.zhiyileiju.cn/WechatIMG559.pic" alt="">
        </span>
    </div>
</template>

<script>
import Clipboard from 'clipboard'
import solarLunar from "../../components/datepick/solarlunar";
import { $order, $orderBindIphone } from "../../api/home_marriage_test"
import { $stat } from "../../api/stat"
export default {
    data () {
        return{
            complaint_buttom_fal:true, //投诉按钮开关
            info:{},//信息
            lunar_calendar_value:{},//日期
            showPayDialog:false,//是否打开手机输入弹框
            tel:'',//手机号
            input_fal:false,//手机号输入框显示隐藏
        }
    },
    created () {
        this.dataRequest()
        this.callback()
  
        $stat("v1_marry_success");
    },
    methods:{
        callback(){
            $order(this.$route.params.id).then( ( res ) => {
                if (res.status === 200 ) {
                    this.info = res.data.data;
                        console.log(this.info)
                    if(!this.info.discount_amount){
                        let fromk = this.$route.query.from;

                        let needCallback = ['xa1','xa2','xa3','xb1','xb2','xb3','xc1','xc2','xc3','xf1','xf2','xf3','xf4','xr1','xc4','xf6','xf8','xc5','xr2','xs1','xs2','xs3','xs4','xn6','xn2','xn3','xn4','xn5','xq2','xq3','xt1','xt3','xz1','xg1','xh1',
                        'xz2','xy1','xf5','xk1','xu1','xp1','xj1','xq1','xt2'
                        ];

                        let callbackId = {
                            xa1:347784,
                            xa2:348896,
                            xa3:348897,
                            xb1:347773,
                            xb2:349191,
                            xb3:348893, 
                            xc1:347788,
                            xc2:348669, 
                            xc3:348882,
                            xf1:381411, 
                            xf2:376895, 
                            xf3:375321,
                            xf4:353521, 
                            xf5:371528, 
                            xf6:360684,
                            xf8:371522,
                            xr1:351636,
                            xc4:353476,
                            xc5:367441,
                            xr2:357971,
                            xs1:356014, 
                            xs2:356015, 
                            xs3:366178, 
                            xs4:367926, 
                            xn6:369370, 
                            xn2:369369,
                            xn3:364220,
                            xn4:364214,
                            xn5:364221,
                            xq3:364193, 
                            xt1:369372, 
                            xq2:373539, 
                            xt3:374133, 
                            xp1:368032, 
                            xz1:370435, 
                            xz2:378591,  
                            xg1:371473,
                            xh1:372225,
                            xy1:385110, 
                            xk1:392241, 
                            xu1:389513, 
                            xj1:391892, 
                            xq1:373528,
                            xt2:369373,
                            
                        }
                        if(needCallback.indexOf(fromk) > -1){
                            _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                            // console.log('100%',{event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                        };


                        let needCallback_7 = [ //7%回传快手
                            ];
                        let callbackId_7 ={
                            
                            
                        }

                        if(needCallback_7.indexOf(fromk) > -1 && Math.random() < 0.07 ){
                            // console.log('5%')
                            _ks_trace.push({event: 'form', convertId: callbackId_7[fromk], cb: function(){ }})
                        }

                        // let needCallback_95 = [ //95%回传快手
                        //     // 'xf5'
                        //     ];
                        // let callbackId_95 ={
                        //     // xf5:371528,
                        // }

                        // if(needCallback_95.indexOf(fromk) > -1 && Math.random() < 0.95 ){
                        //     // console.log('95%')
                        //     _ks_trace.push({event: 'form', convertId: callbackId_95[fromk], cb: function(){ }})
                        // }

                        // let needCallback_15 = [ //15%回传快手
                        //     'xp1'
                        //     ];
                        // let callbackId_15 ={
                        //     xp1:368032, 
                        // }

                        // if(needCallback_15.indexOf(fromk) > -1 && Math.random() < 0.15 ){
                        //     // console.log('95%')
                        //     _ks_trace.push({event: 'form', convertId: callbackId_15[fromk], cb: function(){ }})
                        // }
                    }else{
                        // let fromk = this.$route.query.from;
                        // let needCallback = ['xxxx','xxx','xxx'];
                        // let callbackId ={
                        //     xxxx:369373,
                        //     xxx:371528,
                        //     xxx:373528, 
                        // }

                        // if(needCallback.indexOf(fromk) > -1 && Math.random() < 0.95 ){
                        //     _ks_trace.push({event: 'form', convertId: callbackId[fromk], cb: function(){ }})
                        // }
                    }
                }
            })
        },
        dataRequest () {//数据请求
            $order(this.$route.params.id).then( ( res ) => {
                if (res.status === 200 ) {
                    this.info = res.data.data;
                    let date_arr = res.data.data.pub_birth_date.substr(0,10).split("-")
                    let time_arr = res.data.data.pub_birth_date.substr(11,8).split(":")

                    date_arr.push(...time_arr)
                    this.lunar_calendar_value = solarLunar.solar2lunar(//获取农历
                        date_arr[0],
                        date_arr[1],
                        date_arr[2],
                    );
                    this.lunar_calendar_value.tiem_ = time_arr[0]//时
                    // console.log(this.lunar_calendar_value)
                    if (res.data.data.bind_iphone === null) {
                        this.showPayDialog = true;
                    }
                    //判断是否支付，如果未支付跳转支付页
                    if ( res.data.data.order_status === '已支付' || res.data.data.order_status === '已完成') {

                    }else{
                        this.$router.replace({path:`/home_marriage_test/payment/${res.data.data.id}`}) 
                    }
                }
            })
        },
        subtel() {//提交输入的手机号
            let val = this.tel;
            let myreg = /^[1][2,3,4,5,7,8,9][0-9]{9}$/;
            if (!myreg.test(val) || val == "") {
                this.$dialog({ message: "手机号填写不正确" });
            }else{
                let orderBindIphone_data_ = {
                    order_id: this.$route.params.id,
                    bind_iphone: val,
                }
                    $orderBindIphone(orderBindIphone_data_).then( ( res ) => {
                            this.$toast.success("绑定成功");
                            this.showPayDialog = false;
                            this.dataRequest()
                            // console.log('res',res)
                    }).catch (()=>{
                             this.$toast.fail("绑定失败");
                    })
              
                        
            }
        },

        publicMark(){//打开关注公众号
            $stat("v1_marry_success_wechat_c");
            // location.href = "weixin://dl/business/?t=Yqug7dl8YOe";
            // location.href = "weixin://dl/business/?t=6j6j18WsuNb";
            // location.href = " weixin://dl/business/?t=f3ScNUQnGVo";
            location.href = "weixin://dl/business/?t=CHFL4GVhblq";
        },
        openWeixin() {//打开微信
            location.href = "weixin://";
        },
        buttomResult() {
            $stat("v1_marry_success_result");
            this.$router.push('/home_marriage_test/payment/usersBinding')
        },
        copy() {//复制按钮
            var clipboard = new Clipboard('.tag-read')
            clipboard.on('success', e => {
                this.$toast.success("复制成功");
                // console.log('复制成功')
                //  释放内存
                // clipboard.destory()
            })
                clipboard.on('error', e =>{
                // 不支持复制
                console.log('该浏览器不支持复制')
                // 释放内存
                // clipboard.destory()
            })
        },
        displayInputField () {//点击显示手机号输入框
            this.input_fal = true
        },
        complaintButtom(){//投诉
         switch ('/'+this.$route.path.split('/')[1]) { //埋点
            case '/home_marriage_test':
                $stat("v1_marry_success_complaint");
                break; 
            case '/home_marriage_test_v2':
                $stat("v2_marry_success_complaint");
                break;
            case '/home_marriage_test_v3':
                $stat("v3_marry_success_complaint");
                break;
        
            default:
                break;
        }
            
            window.location.href = 'https://www.wjx.top/vm/hd0JNnc.aspx'
        },
    }
}
</script>

<style lang="scss" scoped>
#succeed{
    width: 100%;
    header{
        width: 100%;
        h1{
            height: 1.04rem;
            font-size: .36rem;
            line-height: 1.04rem;
            text-align: center;
            color: #F4F4F4;
            background: #525252;
        }
    }
    section{
        padding: .16rem;
        background: #fcf9eb;
        height: 100%;
        .card_box{
            position: relative;
            background: #FFFFFF;
            padding-top: .01rem;
            
            h3{
                font-size: .32rem;
                text-align: center;
                font-weight: 600;
            }
            .text_box{
                position: relative;
                padding: .15rem .41rem;
                border: 1px solid #04C967;
                margin-left: .35rem;
                width: 5.6rem;
                margin-bottom: .4rem;
                p{
                    font-size: .28rem;
                    width: 4.5rem;
                    span{
                        font-size: .22rem;
                        color: #1b95d4;
                    }
                }
                button{
                    position: absolute;
                    top:.36rem;
                    right: .44rem;
                    width: .98rem;
                    height: .43rem;
                    font-size: .27rem;
                    color: #fff;
                    border-radius: .05rem;
                    background: #04C967;
                }
            }
            
        }   
        .card_box:first-child,.info{
            text-align: center;
            padding-top: .5rem;
            background: url("https://cdn.zhiyileiju.cn/5d5057941af5857bf368445a2b537af6.jpeg") ;
            background-size:  100% 100%;
            padding-bottom: .5rem;
            i{
                display: inline-block;
                width: 2.1rem;
                // height: 1.46rem;
            }
            h1,h2{
                text-align: center;
                font-weight: 400;
            }
            h1{
                font-size: .5rem;
                color: #020202;
                margin-bottom: .33rem;
            }
            h2{
                font-weight: 600;
                font-size: .4rem;
                color: #FD0101;
            }
            .buttom_box{
                position: relative;
                text-align: center;
                width: 5.69rem;
                height: 1.06rem;
                border-radius: 5.69rem;
                background: #EC703A;
                // margin: 1.33rem auto;
                span{
                    position: absolute;
                    top: 0;
                    left: .7rem;
                    right: 0;
                    font-size: .4rem;
                    color: #fff;
                    height: 1.1rem;
                    line-height: 1.1rem;
                }
                i{
                    position: absolute;
                    top:  -23%;;
                    left: 18%;
                    display: inline-block;
                    width: .48rem;
                    height: .28rem;
                    
                }
            }
            p{
                font-size: .24rem;
                color: #020202;
            }
            h4{
                text-align: left;
                font-size: .32rem;
                font-weight: 400;
                color:#020202;
                line-height: .7rem;
                padding-left: .9rem;
                span{
                    margin-right:4em ;
                }
            }
        }
        //点我打开微信
         .buttom_box{
                position: relative;
                text-align: center;
                width: 6.4rem;
                height: 1.1rem;
                border-radius: .14rem;
                background: #04C967;
                margin: .33rem auto;
                span{
                    position: absolute;
                    top: 0;
                    left: .1rem;
                    right: 0;
                    font-size: .4rem;
                    color: #fff;
                    height: 1.1rem;
                    line-height: 1.1rem;
                }
                i{
                    position: absolute;
                    top:  -14%;
                    left: 23%;
                    display: inline-block;
                    width: .48rem;
                    height: .28rem;
                    
                }
            }
        
        h3{
            font-weight: 400;
            font-size: .32rem;
            color: #000000;
            margin: .4rem 0;
            background: #fcf9eb;
        }
    }
    footer{
        background: #fcf9eb;
        // .qr_code_box{
        // }
        h3{
            background: #fcf9eb;
            font-weight: 400;
            font-size: .32rem;
            color: #000000;
            // margin: .4rem 0;
            padding: .16rem;
            margin-top: -0.2rem;
        }
    }
    // 输入手机号弹窗
    .van-dialog{
        border-radius: .6rem !important;

    }
    .dialogbox {
        padding-bottom: .7rem;
        display: flex;
        flex-direction: column;
        .tip {
            font-size: .45rem;
            font-family: Microsoft YaHei;
            font-weight: bold;
            text-align: center;
            color: #000;
            margin: .7rem auto;
            margin-bottom: 20px;
        }
        .formb {
            border: 1px solid #d0021b;
            border-radius: 3px;
            width: 5.7rem;
            margin-left: .6rem;
            display: flex;
            justify-content: center;
            align-items: center;
            .span_text{
                line-height: 1rem;
                font-weight: 500;
                color: #d0021b;
                font-size: .4rem;
            }
        }
        .input_css{
            font-size: .45rem;
        }
        ::-webkit-input-placeholder { font-size: .3rem; }
        :-moz-placeholder { font-size: .3rem;}
        ::-moz-placeholder{ font-size: .3rem;}
        :-ms-input-placeholder{ font-size: .3rem;}

        .van-button__icon{
            font-size: 1.9em !important;
        }
        .button_text{
            font-size: .5rem;
        }
        .button_text_{
            .van-button__text{
                color: #000 !important;
            }
        }
        .agreetip {
            text-align: center;
            margin-top: 15px;
            font-size: 14px;
            font-family: Microsoft YaHei;
            font-weight: 400;
            color: #525252;
        }
        .agree {
            background: url("https://cdn.zhiyileiju.cn/yes_icon.png") no-repeat center;
            background-color: #b95353;
            background-size: 12px;
            display: inline-block;
            border-radius: 2px;
            width: 15px;
            height: 15px;
            margin-right: 10px;
        }

    }
    //投诉
        .fixation_complaint{
            position: fixed;
            width: .48rem;
            top: 3.02rem;
            right: 0;
            z-index: 10;
        }
}
</style>